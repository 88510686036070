import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import RNLanguageDetector from '@os-team/i18next-react-native-language-detector';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';
import { Helper } from './src/Helper';

import commonEN from './lang/en/common.json';
import loginEN from './lang/en/login.json';
import myPayEN from './lang/en/myPay.json';
import myBenefitsEN from './lang/en/myBenefits.json';
import timeEN from './lang/en/time.json';
import helpEN from './lang/en/help.json';
import profileEN from './lang/en/profile.json';
import registerEN from './lang/en/register.json';
import companyEN from './lang/en/company.json';
import conferenceEN from './lang/en/conference.json';
import riskAndComplianceEN from './lang/en/riskAndCompliance.json';
import employeesEN from './lang/en/employees.json';

import commonES from './lang/es/common.json';
import loginES from './lang/es/login.json';
import myPayES from './lang/es/myPay.json';
import myBenefitsES from './lang/es/myBenefits.json';
import timeES from './lang/es/time.json';
import helpES from './lang/es/help.json';
import profileES from './lang/es/profile.json';
import registerES from './lang/es/register.json';
import companyES from './lang/es/company.json';
import conferenceES from './lang/es/conference.json';
import riskAndComplianceES from './lang/es/riskAndCompliance.json';
import employeesES from './lang/es/employees.json';

const resources = {
    en : {
        common: commonEN,
        login: loginEN,
        myPay: myPayEN,
        myBenefits: myBenefitsEN,
        time: timeEN,
        help: helpEN,
        profile: profileEN,
        register: registerEN,
        company: companyEN,
        conference: conferenceEN,
        riskAndCompliance: riskAndComplianceEN,
        employees: employeesEN
    },
    
    es : {
        common: commonES,
        login: loginES,
        myPay: myPayES,
        myBenefits: myBenefitsES,
        time: timeES,
        help: helpES,
        profile: profileES,
        register: registerES,
        company: companyES,
        conference: conferenceES,
        riskAndCompliance: riskAndComplianceES,
        employees: employeesES
    },
}

if (Helper.isPlatformWeb) {
    i18n.use(XHR);
    i18n.use(LanguageDetector);
}
else {
    i18n.use(RNLanguageDetector);
}

i18n
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        compatibilityJSON: 'v3',
        fallbackLng: 'en',
        supportedLngs: ['en-US', 'en', 'es'],
        resources,
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        }
    });

export default i18n;